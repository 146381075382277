/* Fonts */

@font-face {
    font-family: 'Allavitti';
    font-style: normal;
    font-weight: 300;
    src: url('./Assets/Fonts/AllavittiLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Allavitti';
    font-style: italic;
    font-weight: 300;
    src: url('./Assets/Fonts/AllavittiLightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Allavitti';
    font-style: normal;
    font-weight: 400;
    src: url('./Assets/Fonts/AllavittiRegular.ttf') format('truetype');
}

@font-face {
    font-family: 'Allavitti';
    font-style: normal;
    font-weight: 600;
    src: url('./Assets/Fonts/AllavittiSemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Allavitti';
    font-style: normal;
    font-weight: 700;
    src: url('./Assets/Fonts/AllavittiBold.ttf') format('truetype');
}


:root {
    --secondSectionColor: rgba(82, 138, 128, 0.06);
    --muteTextColor: #c4c4c4;
}

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pattern-left-box {
    position: absolute;
    left: 0%;
    bottom: 0%;
    top: 0%;
    height: 100vh;
    background-image: url('./Assets/Images/naqshleft.png');
    background-size: cover;
    background-position: center center;
    /* width: 20%; */

}

.pattern-right-box {
    position: absolute;
    right: 0%;
    bottom: 0%;
    top: 0%;
    height: 100vh;
    background-image: url('./Assets/Images/naqshrigth.png');
    background-size: cover;
    background-position: center center;
    /* width: 20%; */
    background-repeat: repeat;
}


.snap-parent-y-mandatory {
    /*scroll-snap-type: y mandatory;*/
    /*overflow: hidden auto;*/
    /*height: 100vh;*/
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

.snap-child-start {
    z-index: 10;
    /*scroll-snap-align: start;*/
}

.mute-text {
    color: var(--muteTextColor);
}

.section-wrapper {
    position: relative;
}

/*section {*/
/*    overflow: auto;*/
/*}*/

section::-webkit-scrollbar {
    display: none;
}

.secondaryText {
    font-size: 18px;
}

[data-rmiz] {
    position: relative;
}

[data-rmiz-ghost] {
    position: absolute;
    pointer-events: none;
}

[data-rmiz-btn-zoom],
[data-rmiz-btn-unzoom] {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    border: none;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    color: #fff;
    height: 40px;
    margin: 0;
    outline-offset: 2px;
    padding: 9px;
    touch-action: manipulation;
    width: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

[data-rmiz-btn-zoom]:not(:focus):not(:active) {
    position: absolute;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    pointer-events: none;
    white-space: nowrap;
    width: 1px;
}

[data-rmiz-btn-zoom] {
    position: absolute;
    inset: 10px 10px auto auto;
    cursor: zoom-in;
}

[data-rmiz-btn-unzoom] {
    position: absolute;
    inset: 20px 20px auto auto;
    cursor: zoom-out;
    z-index: 1;
}

[data-rmiz-content="found"] img,
[data-rmiz-content="found"] svg,
[data-rmiz-content="found"] [role="img"],
[data-rmiz-content="found"] [data-zoom] {
    cursor: zoom-in;
}

[data-rmiz-modal]::backdrop {
    display: none;
}

[data-rmiz-modal][open] {
    position: fixed;
    width: 100vw;
    height: 100vh;
    max-width: none;
    max-height: none;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    overflow: hidden;
}

[data-rmiz-modal-overlay] {
    position: absolute;
    inset: 0;
    transition: background-color 0.3s;
}

[data-rmiz-modal-overlay="hidden"] {
    background-color: rgba(255, 255, 255, 0);
}

[data-rmiz-modal-overlay="visible"] {
    background-color: rgba(0, 0, 0, 0.35);
}

[data-rmiz-modal-content] {
    position: relative;
    width: 100%;
    height: 100%;
}

[data-rmiz-modal-img] {
    position: absolute;
    cursor: zoom-out;
    image-rendering: high-quality;
    transform-origin: top left;
    transition: transform 0.3s;
}

@media (prefers-reduced-motion: reduce) {
    [data-rmiz-modal-overlay],
    [data-rmiz-modal-img] {
        transition-duration: 0.01ms !important;
    }
}

.moreBtn {
    opacity: 0;
}

.animateFromLeft {
    animation: fadeFromTop 1s ease-in-out forwards;
}

@keyframes fadeFromTop {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

::-webkit-scrollbar {
    width: 5px;
    z-index: 5000;
}

::-webkit-scrollbar-thumb {
    background: #202834;
}

::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
}

.overlay-drag {
    opacity: 0;
}

.drag-and-drop:hover .overlay-drag {
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .drag-and-drop {
        min-height: 250px;
        max-height: 250px;
    }

}

